/** @jsx jsx */
import { jsx, Themed, Flex } from 'theme-ui'
import { motion } from 'framer-motion'
import { ShareIcon } from './ShareIcon'
import ShareType from './ShareType'

const Share = ({ types = [], siteUrl, postPath, postTitle }) => {
  // ANIMATION
  const headingVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.1, duration: 1 },
    },
  }

  const shareVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.2, duration: 1 },
    },
  }

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row', 'row', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={headingVariants}
        sx={{ display: 'flex', pb: [3, 0, 0, 0] }}
      >
        <Themed.h5 sx={{ my: 3, mx: 2 }}>Want to share?</Themed.h5>
      </motion.div>
      <Flex sx={{ my: 0, mx: 2 }}>
        {types.map((type, i) => (
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            variants={shareVariants}
            sx={{ display: 'flex', mx: 2, alignItems: 'center' }}
          >
            <ShareType
              type={type}
              siteUrl={siteUrl}
              postPath={postPath}
              postTitle={postTitle}
              key={i}
            >
              <ShareIcon type={type} key={i} />
            </ShareType>
          </motion.div>
        ))}
      </Flex>
    </Flex>
  )
}

export default Share
