/** @jsx jsx */
import { jsx, Box, Themed } from 'theme-ui'
import { motion } from 'framer-motion'
import { format } from 'date-fns'
import BlogTagLink from './BlogTagLink'
import AuthorList from './AuthorList'

function BlogPostHero(props) {
  const {
    title,
    titleFontColor,
    categories,
    authors,
    publishedAt,
    publishedAtFontColor,
  } = props

  const titleColor = titleFontColor ? titleFontColor.hex : 'inherit'
  const publishedAtColor = publishedAtFontColor
    ? publishedAtFontColor.hex
    : 'inherit'

  // ANIMATION
  const categoryTagVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.2, duration: 1 },
    },
  }
  const headingVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.3, duration: 1 },
    },
  }
  const publishedDateVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.4, duration: 1 },
    },
  }
  const authorVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.5, duration: 1 },
    },
  }

  return (
    <Box sx={{ textAlign: 'center', py: 5 }}>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={categoryTagVariants}
      >
        <BlogTagLink categories={categories} />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={headingVariants}
      >
        <Themed.h2 sx={{ color: titleColor }}>{title}</Themed.h2>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={publishedDateVariants}
        sx={{ m: 0, pt: '4', pb: '2', px: '1' }}
      >
        <Themed.p
          sx={{
            color: publishedAtColor,
            variant: 'text.paragraph',
            fontWeight: '400',
            mt: 0,
            mb: 0,
          }}
        >
          Published on {format(new Date(publishedAt), 'MMMM do, yyyy')}
        </Themed.p>
      </motion.div>
      {authors.length > 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          variants={authorVariants}
        >
          <AuthorList items={authors} title="Authors" />
        </motion.div>
      )}
    </Box>
  )
}

export default BlogPostHero
