/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Fragment } from 'react'
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
  EmailShareButton,
} from 'react-share'

function ShareType({ type, siteUrl, postPath, postTitle, children }) {
  const typeName = type.type
  const twitterName = type.twitterUsername
  const postUrl = `${siteUrl}${postPath}`

  return (
    <Fragment>
      {typeName === 'twitter' && (
        <TwitterShareButton url={postUrl} via={twitterName}>
          {children}
        </TwitterShareButton>
      )}
      {typeName === 'linkedin' && (
        <LinkedinShareButton
          url={postUrl}
          title={postTitle}
          source={siteUrl}
          summary="pop sumary"
        >
          {children}
        </LinkedinShareButton>
      )}
      {typeName === 'facebook-f' && (
        <FacebookShareButton url={postUrl}>{children}</FacebookShareButton>
      )}
      {typeName === 'email' && (
        <EmailShareButton url={postUrl} subject={postTitle}>
          {children}
        </EmailShareButton>
      )}
    </Fragment>
  )
}

export default ShareType
