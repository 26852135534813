import React from 'react'
import { graphql } from 'gatsby'
import Errors from '../components/Errors'
import Seo from '../components/seo/Seo'
import { toPlainText } from '../lib/helpers'
import Layout from '../components/Layout'
import BlogPost from '../components/BlogPost'

const BlogPostTemplate = props => {
  const { data, errors, path } = props

  if (errors) {
    return <Errors errors={errors} />
  }
  // Site data
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site Settings". Open the studio at http://localhost:4444 and add some content to "Site Settings" and restart the development server.',
    )
  }

  // Footer
  const footerNavigation = site.footerNavigation
  const footerText = site.footerText

  // Social Links
  const socialLinks = site.socialLinks

  // Post data
  const post = data && data.post

  // Nav Menu items
  const menuItems = post.navMenu && (post.navMenu.items || [])

  // SEO
  const postSeo = post.seoPost || []

  // Canonical URL
  const postURL = path
  const siteCanonicalURL = site.url
  const URLCanonical = postURL
    ? [siteCanonicalURL, postURL].join('')
    : siteCanonicalURL

  // Page title
  const seoPostTitle = !postSeo.seoTitle
    ? `${post.title} | `
    : `${postSeo.seoTitle} | `

  // Post description
  const postSeoExcerpt = toPlainText(post._rawExcerpt)
  const seoDescription = !postSeo.description
    ? postSeoExcerpt
    : postSeo.description

  // Keywords
  const postKeywords = (postSeo && postSeo.keywords) || []
  const siteKeywords = site.seo.keywords
  const seoKeywords = postKeywords.length > 0 ? postKeywords : siteKeywords

  // Open Graph Image
  const postGraphImage =
    (postSeo && postSeo.openGraphImage) || site.seo.openGraphImage

  return (
    <Layout
      navMenuItems={menuItems}
      siteTitle={site.title}
      svg={site.useLogo}
      svgColor={site.logoColor}
      footerNav={footerNavigation}
      footerCopy={footerText}
      social={socialLinks}
    >
      {post && (
        <Seo
          title={seoPostTitle}
          description={seoDescription}
          image={postGraphImage}
          canonicalURL={URLCanonical}
          keywords={seoKeywords}
        />
      )}

      {errors && <Errors errors={errors} />}
      {post && (
        <BlogPost {...post} siteUrl={siteCanonicalURL} postPath={postURL} />
      )}
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      publishedAtColor {
        hex
      }
      categories {
        _id
        name
        description
      }
      navMenu {
        ...NavMenu
      }
      title
      titleColor {
        hex
      }
      slug {
        current
      }
      share {
        type
        twitterUsername
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawModules(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
      seoPost {
        seoTitle
        description
        keywords
        openGraphImage {
          ...SanityImage
          alt
          caption
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      url
      useLogo
      logoColor {
        hex
      }
      seo {
        seoTitle
        description
        keywords
        openGraphImage {
          ...SanityImage
          alt
          caption
        }
      }
      footerNavigation {
        page {
          title
        }
        slug {
          current
        }
      }
      socialLinks {
        type
        link
      }
    }
  }
`
