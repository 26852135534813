/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

// 'fab' represents all of the free 'fab' brand icons in @fortawesome/free-brands-svg-icons
// So any of the brand icons in that package may be referenced by icon name as a string
// plus added the faEnvelope specificly
library.add(fab, faEnvelope)

export const ShareIcon = ({ type }) => {
  const typeName = type.type

  const [active, setActive] = useState({ hovered: false, duration: 4000 })

  const [animationProps, setAnimationProps] = useState({
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(25deg)' },
  })

  const context = useThemeUI()
  const {
    theme: { colors },
  } = context

  const fromColorFill = colors.heading
  const toColorFill = colors.primary

  const springProps = useSpring({
    from: active.hovered ? animationProps.from : animationProps.from,
    to: active.hovered ? animationProps.to : animationProps.from,
    config: active.hovered ? { duration: 300 } : { duration: active.duration },
  })

  const iconStyles = {
    fontSize: '1.7em',
    m: 0,
    color: active.hovered ? toColorFill : fromColorFill,
    '&:Hover': {
      cursor: 'pointer',
    },
  }

  useEffect(() => {
    const socialName = typeName

    if (socialName === 'twitter') {
      return setAnimationProps({
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(25deg)' },
      })
    }
    if (socialName === 'linkedin') {
      return setAnimationProps({
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(-90deg)' },
      })
    }
    if (socialName === 'facebook-f') {
      return setAnimationProps({
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(180deg)' },
      })
    }
    if (socialName === 'email') {
      return setAnimationProps({
        from: { transform: 'scale(1, 1)' },
        to: { transform: 'scale(0.8,0.8)' },
      })
    }
  }, [typeName])

  return (
    <animated.div style={springProps} sx={{ display: 'flex' }}>
      {typeName === 'email' ? (
        <FontAwesomeIcon
          icon={faEnvelope}
          sx={iconStyles}
          onMouseEnter={() => {
            setActive({ hovered: true, duration: 500 })
          }}
          onMouseLeave={() => {
            setActive({ hovered: false, duration: 500 })
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={['fab', typeName]}
          sx={iconStyles}
          onMouseEnter={() => {
            setActive({ hovered: true, duration: 500 })
          }}
          onMouseLeave={() => {
            setActive({ hovered: false, duration: 500 })
          }}
        />
      )}
    </animated.div>
  )
}
