/** @jsx jsx */
import { jsx, Flex, Themed } from 'theme-ui'
// import { format, formatDistance, differenceInDays } from 'date-fns'
import { Fragment } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'
import { PageContainer } from '../styles/Layouts'
import BlogPostHero from './BlogPostHero'
import Cta from './CTA'
import HeroBackgroundImage from './HeroBackgroundImage'
import PortableText from './PortableText'
import Share from './Share'

function BlogPost(props) {
  const {
    _rawModules,
    _rawBody,
    authors,
    categories,
    title,
    titleColor,
    publishedAt,
    publishedAtColor,
    share,
    siteUrl,
    postPath,
  } = props

  const moduleContent = (_rawModules || [])
    .filter(item => !item.disabled)
    .map((mod, i) => {
      let element = null
      switch (mod._type) {
        case 'heroBackgroundImage':
          element = <HeroBackgroundImage key={mod._key} {...mod} />
          break
        case 'ctaPlug':
          element = <Cta key={mod._key} {...mod} />
          break
        default:
          element = null
      }
      return element
    })

  // ANIMATION
  const bannerModuleVariants = {
    hidden: {
      opacity: 0,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.1,
        duration: 1.4,
        ease: 'easeInOut',
        bounce: 0.4,
      },
    },
  }

  const bodyVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.2,
        duration: 1,
        ease: 'easeInOut',
        bounce: 0.4,
      },
    },
  }

  const shareVariants = {
    hidden: {
      opacity: 0,
      scale: 0.3,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', delay: 0.2, duration: 1 },
    },
  }

  return (
    <article>
      <Fragment>
        {/* Static setting of PageContainer layout for HERO */}
        <PageContainer
          variantType="layout.containerBlogPost"
          module="BlogPostHero"
          padTopCondensed={true}
          padBottomCondensed={true}
        >
          <BlogPostHero
            title={title}
            titleFontColor={titleColor}
            categories={categories}
            authors={authors}
            publishedAt={publishedAt}
            publishedAtFontColor={publishedAtColor}
          />
        </PageContainer>
        {/* Full width modules */}
        <motion.div
          variants={bannerModuleVariants}
          initial="hidden"
          animate="animate"
        >
          {_rawModules && moduleContent}
        </motion.div>
        {/* Static setting of PageContainer layout for BODY*/}
        <PageContainer
          variantType="layout.containerBlogPost"
          module="BlogPostBody"
          padTopCondensed={true}
          padBottomCondensed={false}
        >
          <motion.div
            variants={bodyVariants}
            initial="hidden"
            animate="animate"
            sx={{ py: 4 }}
          >
            {_rawBody && <PortableText blocks={_rawBody} />}
          </motion.div>
          {share.length > 0 && (
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                my: 4,
              }}
            >
              <Share
                types={share}
                siteUrl={siteUrl}
                postPath={postPath}
                postTitle={title}
              />
            </Flex>
          )}
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.1 }}
            variants={shareVariants}
            sx={{ py: 3 }}
          >
            <Link to={'/blog/'} style={{ textDecoration: 'none' }}>
              <Themed.p sx={{ variant: 'links.noUnderline' }}>
                {' '}
                ←&nbsp;Back to all posts
              </Themed.p>
            </Link>
          </motion.div>
        </PageContainer>
      </Fragment>
    </article>
  )
}

export default BlogPost
